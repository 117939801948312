import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { GeneralContext } from "./contexts/general.context";
import {
  ConfirmAccountPage,
  CreateProfilePage,
  DashBoardPage,
  LandingPage,
  LoginPage,
  NotFound,
} from "./pages";
import { FAQ } from "./pages/FAQ";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";

function App() {
  const { setIsLoggedIn } = useContext(GeneralContext);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) setIsLoggedIn(true);
    //eslint-disable-next-line
  }, []);

  return (
    <div className="h-screen w-full">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/request" element={<ConfirmAccountPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/createProfile" element={<CreateProfilePage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute>
              <DashBoardPage />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
