import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TranscriptManagementContext } from "../../../contexts/transcriptManagement.context";
import { setGenericModalId } from "../../../Redux/features/modalsSlice";
import { ImSpinner3 } from "react-icons/im";
import { Table } from "../../../components";

const ProficiencyInEnglishManagement = ({ ViewModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [transcriptDetails, setTranscriptDetails] = useState();
  const dispatch = useDispatch();

  const { requireReviewTranscripts, getRequireReviewTranscripts } = useContext(
    TranscriptManagementContext
  );

  useEffect(() => {
    getRequireReviewTranscripts(true);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requireReviewTranscripts) setIsLoading(false);
  }, [requireReviewTranscripts]);

  const handlePreviewButtonClick = (orderNo) => {
    const temp = requireReviewTranscripts.find(
      (item) => item.requestOrderNumber === orderNo
    );

    // try {

    const {
      // requestOrderNumber,
      // trancriptUseCaseType,
      // address1: destination,
      // address2: secondDestination,
      // city: { name: CityName },
      // country: { name: CountryName },
      // regNumber,
      // student: {
      //   user: { fullName, email, phoneNumber },
      //   admission: {
      //     department: { department_Name: departmentName },
      //   },
      // },
      transcriptDocument,
      // referenceNumber = "",
      // resultType,
      // destinationContactNumber,
      // organisation,
    } = temp;

    const attachingDocumentUrlArray = JSON.parse(
      transcriptDocument[0]?.attachingDocumentUrl || "[]"
    );
    const statementOfResultURL =
      transcriptDocument[0]?.clearanceORpaymentRecieptsUrl;

    const res = {
      requestOrderNumber: temp?.requestOrderNumber,
      trancriptUseCaseType: temp?.trancriptUseCaseType,
      destination: temp?.address1,
      secondDestination: temp?.address2,
      CityName: temp?.city?.name,
      CountryName: temp?.country?.name,
      regNumber: temp?.regNumber,
      departmentName:
        temp?.student?.user?.admission?.department?.department_Name,
      fullName: temp?.student?.user?.fullName,
      email: temp?.student?.user?.email,
      phoneNumber: temp?.student?.user?.phoneNumber,
      attachingDocumentUrlArray,
      referenceNumber: "",
      resultType: temp?.resultType,
      destinationContactNumber: temp?.destinationContactNumber,
      organisation: temp?.organisation,
      statementOfResultURL,
    };

    setTranscriptDetails(res);
    dispatch(setGenericModalId("UnprocessedTranscriptView"));
    // } catch (err) {
    //   console.log(err, "Something went wrong");
    // }
  };

  const columns = [
    { value: "Serial No." },
    { value: "Order No." },
    { value: "Full Name" },
    { value: "Request Date" },
    { value: "Matric No." },
    { value: "Shipping Address" },
    { value: "Destination Email" },
    { value: "Actions" },
  ];

  const data = requireReviewTranscripts?.map((item, index) => ({
    "Serial No.": index + 1,
    "Order No.": item.requestOrderNumber,
    "Full Name": item.student.user.fullName,
    "Request Date": item.createdAt.split("T")[0],
    "Matric No.": item.regNumber,
    "Shipping Address": item.address1,
    "Destination Email": item.destinationEmail,
    Preview: (
      <button onClick={() => handlePreviewButtonClick(item.requestOrderNumber)}>
        Preview
      </button>
    ),
  }));

  return (
    <>
      <div className="grid w-full h-full">
        {isLoading ? (
          <ImSpinner3 className="animate-spin place-self-center" size={32} />
        ) : (
          <Table columns={columns} data={data} />
        )}
      </div>
      <ViewModal transcriptDetails={transcriptDetails} />
    </>
  );
};

export default ProficiencyInEnglishManagement;
