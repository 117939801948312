import { toast } from "react-toastify";

import { transcriptApi } from "../transcriptCore";

const transcriptRequestApi = transcriptApi.injectEndpoints({
  endpoints: (builder) => ({
    getAwaitingFinanceApprovalTranscripts: builder.query({
      query: (isProficiency) => ({
        url: `api/v1/Transcript/GetAwaitingFinanceApprovalTranscripts`,
        method: "GET",
        params: { isProficiency: isProficiency ? isProficiency : false },
      }),
    }),

    markTranscriptAsProcessingForReview: builder.query({
      query: ({ OrderNumber, isProficiency }) => ({
        url: `api/v1/Transcript/MarkTranscriptAsProcessingForReview?RequestOrderNumber=${OrderNumber}&isProficiency=${isProficiency}`,
        method: "GET",
      }),
      async onQueryStarted({ isProficiency }, { queryFulfilled }) {
        try {
          await queryFulfilled;
          if (isProficiency) {
            toast.success("Proficiency in English approved successfully!");
          } else {
            toast.success("Transcript approved successfully!");
          }
        } catch (err) {
          toast.error(err.message || "Error approving transcript!");
        }
      },
    }),
    getFinanceTranscriptsSummary: builder.query({
      query: () => ({
        url: `api/v1/Transcript/GetTranscriptsSummaryBy`,
        method: "GET",
      }),
    }),
    getTransactionTableTranscripts: builder.query({
      query: (arg) => ({
        url: `api/v1/Transcript/GetTranscriptsBy`,
        method: "GET",
        params: { ...arg },
      }),
    }),
    getAuditTrailTableTranscripts: builder.query({
      query: (arg) => ({
        url: `api/v1/Transcript/GetTranscriptAuditTrailBy`,
        method: "GET",
        params: { ...arg },
      }),
    }),
    getPaymentReport: builder.query({
      query: (arg) => ({
        url: `api/v1/Admin/GetPayment`,
        method: "GET",
        params: { ...arg },
      }),
    }),
  }),
});

export const {
  useMarkTranscriptAsProcessingForReviewQuery,
  useGetAwaitingFinanceApprovalTranscriptsQuery,
  useLazyMarkTranscriptAsProcessingForReviewQuery,
  useGetFinanceTranscriptsSummaryQuery,
  useGetTransactionTableTranscriptsQuery,
  useGetAuditTrailTableTranscriptsQuery,
  useLazyGetAuditTrailTableTranscriptsQuery,
  useLazyGetTransactionTableTranscriptsQuery,
  useGetPaymentReportQuery,
} = transcriptRequestApi;
