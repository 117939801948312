import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card } from "../../UI";
import { GeneralContext } from "../../contexts/general.context";
import CurrencyModal from "../CurrencyModal/CurrencyModal";
import { PaymentModal } from "./PaymentModal";
import { ShippingInfo } from "./ShippingInfo";

const PayTranscript = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const resultType = Number(window.location.href.split("result-type=")[1]);
  const orderNo = Number(
    window.location.href.split("order-no=")[1].split("&")[0]
  );
  const feeType = Number(
    window.location.href.split("fee-type=")[1].split("&")[0]
  );

  const copyType = resultType === 1 ? "Hard Copy" : "Soft Copy";

  const { initializePaymentSchema } = useContext(GeneralContext);
  initializePaymentSchema.RequestOrderNumber = orderNo;
  initializePaymentSchema.feeType = feeType;
  initializePaymentSchema.resultType = copyType

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const content = {
    "Soft Copy": (
      <>
        {currentStep === 0 && (
          <CurrencyModal handleBack={handleBack} handleNext={setCurrentStep} />
        )}
        {currentStep === 1 && (
          <PaymentModal onClose={setCurrentStep} modal={false} />
        )}
      </>
    ),
    "Hard Copy": (
      <>
        {currentStep === 0 && (
          <CurrencyModal handleBack={handleBack} handleNext={setCurrentStep} />
        )}
        {currentStep === 1 && (
          <ShippingInfo
            onClick={setCurrentStep}
            handleBack={setCurrentStep}
            copyType={copyType}
          />
        )}
        {currentStep >= 2 && (
          <PaymentModal onClose={setCurrentStep} modal={true} />
        )}
      </>
    ),
  };

  const renderContent = content[copyType];

  return <Card>{renderContent}</Card>;
};

export default PayTranscript;
