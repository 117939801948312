import React from "react";

import styles from "./Button.module.css";
import { ImSpinner3 } from "react-icons/im";

const Button = ({
  type,
  onClick,
  children,
  disabled,
  className,
  variant,
  loading,
}) => {
  return (
    <button
      className={`${styles.button} ${className} ${
        styles[variant || "primary"]
      }`}
      type={type || "button"}
      onClick={onClick}
      disabled={disabled || false}
    >
      {loading ? <ImSpinner3 className="animate-spin" size={22} /> : children}
    </button>
  );
};

export default Button;
