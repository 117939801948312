import React, { useContext, useEffect, useState } from "react";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";

import { Button } from "../../../UI";
import { GeneralContext } from "../../../contexts/general.context";
import { TranscriptRequestContext } from "../../../contexts/transcriptRequest";
import { CouriersType, ResultType } from "../../../utils/helper";
import { ShippingCourier } from "./ShippingCourier";
import classes from "./ShippingOption.module.css";

const forwardIcon = (
  <HiOutlineArrowSmRight style={{ fontSize: "16px", marginInline: "5px" }} />
);

export const ShippingInfo = ({ handleBack, onClick }) => {
  const [selected, setSelected] = useState();

  const { getCourier, couriers, currency, initializePaymentSchema } =
    useContext(GeneralContext);
  const { setTranscriptInfo, transcriptInfo, copyType } = useContext(
    TranscriptRequestContext
  );


  useEffect(() => {
    getCourier();
    //eslint-disable-next-line
  }, []);

  const handleInput = ({ name, value }, price) => {
    setTranscriptInfo((prev) => ({ ...prev, [name]: value, price }));
    setSelected(value);
  };

  const proceed = (e) => {
    e.preventDefault();
    onClick((prevStep) => prevStep + 1);
  };

  const handleBackBtn = (e) => {
    handleBack((prev) => prev - 1);
  };


  useEffect(() => {
    if (copyType === "Hard Copy") {
      initializePaymentSchema.resultType = ResultType["Hard Copy"];

      initializePaymentSchema.couriersType =
        CouriersType[transcriptInfo?.courier];
    }
    else{
      initializePaymentSchema.resultType = ResultType["Soft Copy"];
    }

    //eslint-disable-next-line
  }, [transcriptInfo]);

  return (
    <div className="flex flex-col gap-4">
      <p className={classes.heading}>Shipping Options</p>
      <p className={classes.subHeading}>
        Please select a shipping company for each request.
      </p>
      <div className={classes.optionsContainer}>
        {couriers.length > 0
          ? couriers.map((courier) => (
              <ShippingCourier
                key={courier.name}
                courier={courier}
                selected={selected}
                currency={currency}
                handleInput={handleInput}
              />
            ))
          : null}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.backButton}
          onClick={handleBackBtn}
          variant="invert"
        >
          <HiOutlineArrowSmLeft
            style={{
              fontSize: "16px",
              marginInline: "5px",
            }}
          />
          Back
        </Button>
        <Button disabled={!selected ? true : false} onClick={proceed}>
          Proceed
          {forwardIcon}
        </Button>
      </div>
    </div>
  );
};
