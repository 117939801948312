import jsPDF from "jspdf";
import { useState } from "react";
import { Button } from "../../../UI";
import { TabPage } from "../../../components";
import GenericModal from "../../../components/Modals/GenericModal";
import { TRANSCRIPT_USE_CASE, DELIVERY_USE_CASE } from "../../../utils/helper";
import styles from "./styles.module.css";
import classNames from "classnames";
import TranscriptManagement from "./TranscriptManagement";
import ProficiencyInEnglishManagement from "./ProficienyInEnglishManagement";

const UnprocessedRequest = () => {
  const [currentTab, setCurrentTab] = useState(1);

  return (
    <TabPage pageTitle="Transcript Management/ Unprocessed Requests">
      <div className={styles.tab_container}>
        <div
          className={classNames(
            styles.tab_item,
            currentTab === 1 && styles.active
          )}
          onClick={() => setCurrentTab(1)}
        >
          Transcripts
        </div>
        <div
          className={classNames(
            styles.tab_item,
            currentTab === 2 && styles.active
          )}
          onClick={() => setCurrentTab(2)}
        >
          Proficiency
        </div>
      </div>
      {currentTab === 1 && <TranscriptManagement ViewModal={ViewModal} />}
      {currentTab === 2 && (
        <ProficiencyInEnglishManagement ViewModal={ViewModal} />
      )}
    </TabPage>
  );
};

export default UnprocessedRequest;

const ViewModal = ({ transcriptDetails }) => {
  const handlePrint = async () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    await pdf.html(document.querySelector("#content-to-print"));
    pdf.save("Transcript Upload Details");
  };
  return (
    <GenericModal id="UnprocessedTranscriptView">
      <div id="content-to-print" className="grid gap-4 my-2">
        <p>Request order number: {transcriptDetails?.requestOrderNumber}</p>
        <p>Name: {transcriptDetails?.fullName}</p>
        <p>Reg number: {transcriptDetails?.regNumber}</p>
        <p>Student email: {transcriptDetails?.email}</p>
        <p>Student phoneNumber: {transcriptDetails?.phoneNumber}</p>
        <p>Organisation: {transcriptDetails?.organisation}</p>
        <p>Destination address: {transcriptDetails?.destination}</p>
        <p>Destination city: {transcriptDetails?.CityName}</p>
        <p>Destination country: {transcriptDetails?.CountryName}</p>
        <p>Department: {transcriptDetails?.departmentName}</p>
        <p>
          Destination contact number:{" "}
          {transcriptDetails?.destinationContactNumber}
        </p>
        {transcriptDetails?.trancriptUseCaseType > 0 && (
          <p>
            Transcript Copy Type:
            {TRANSCRIPT_USE_CASE[transcriptDetails?.trancriptUseCaseType]}
          </p>
        )}
        {transcriptDetails?.resultType > 0 && (
          <p>
            Transcript Delivery Type:
            {DELIVERY_USE_CASE[transcriptDetails?.resultType]}
          </p>
        )}
        {transcriptDetails?.referenceNumber && (
          <p>WES Reference Number: {transcriptDetails?.referenceNumber}</p>
        )}
      </div>
      <Button onClick={handlePrint}>Download Copy of Transcript Details</Button>
      {/* */}
      {transcriptDetails?.statementOfResultURL && (
        <div className="mt-4">
          <p className="text-lg font-semibold mb-2">
            Download Official Document:
          </p>

          <a
            href={transcriptDetails?.statementOfResultURL}
            target="blank"
            className="cursor-pointer text-sm font-normal border border-zinc-700 rounded-sm px-2 py-1 bg-slate-600/30 w-fit"
          >
            View Document
          </a>
        </div>
      )}
      {transcriptDetails?.attachingDocumentUrlArray && (
        <div className="mt-4">
          <p className="text-lg font-semibold mb-2">
            Download Attaching Documents:
          </p>
          {transcriptDetails?.attachingDocumentUrlArray
            ?.filter((item) => item && item.includes("https"))
            .map((item, index) => (
              <div className="flex items-center gap-2 mb-3" key={index}>
                <p>Attaching Document {index + 1}:</p>

                <a
                  href={item}
                  target="blank"
                  className="cursor-pointer text-sm font-normal border border-zinc-700 rounded-sm px-2 py-1 bg-slate-600/30 w-fit"
                >
                  View Document
                </a>
              </div>
            ))}
        </div>
      )}
    </GenericModal>
  );
};
