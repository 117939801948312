import React, { useState } from "react";
import PaymentReportFilter from "./PaymentReportFilter";
import PaymentReportTable from "./PaymentReportTable";
import { TabPage } from "../../../components";

const PaymentReport = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [refetch, setRefetch] = useState(false);
  const [page, setPage] = useState(1)



  return (
    <TabPage pageTitle="Finance/ Payment Report">
      <div className="grid w-full h-full">
        <PaymentReportFilter
          startDate={startDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          endDate={endDate}
          setRefetch={setRefetch}
          refetch={refetch}
          setPage={setPage}
        />
        <PaymentReportTable
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          refresh={refetch}
          page={page}
          setPage={setPage}
          startDate={startDate}
        />
      </div>
    </TabPage>
  );
};

export default PaymentReport;
