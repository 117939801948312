import React, { useRef } from "react";
import ReactPaginate from "react-paginate";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import classes from "./Table.module.css";
import { CSVLink } from "react-csv";

const Table = ({
  columns,
  data,
  title,
  id,
  pageCount,
  itemCount,
  ChangePage,
  currentPage,
  downloadable,
}) => {
  const tableScroll = useRef();
  const scrollToTop = () => {
    tableScroll.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div className={classes["table__page-container"]} ref={tableScroll}>
      <div className={classes["table_header"]}>
        {title && <h3 className="my-2 text-lg ">{title || ""}</h3>}
        {downloadable && (
          <CSVLink
            data={data}
            headers={columns}
            className="border bg-blue-700 p-2 rounded-md text-white m-4"
          >
            Export CSV
          </CSVLink>
        )}
      </div>
      <div id={id || ""} style={{ width: "100%", overflowX: "auto" }}>
        <table>
          <thead>
            <tr>
              {columns.map((item, index) => (
                <th key={index}>{item.value}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!data?.length && (
              <tr className={classes.no_data}>
                <td> No Data</td>
              </tr>
            )}
            {data?.map((item, index) => {
              const array = Object.entries(item);
              return (
                <tr key={index}>
                  {array.map(([name, value]) => (
                    <td data-cell={name} key={name} title={name}>
                      {value}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {pageCount && (
          <div className={classes.pagination_container}>
            <div className={classes.page_count}>Total Record: {itemCount}</div>
            <ReactPaginate
              previousLabel={<PiCaretLeftBold />}
              nextLabel={<PiCaretRightBold />}
              pageCount={pageCount}
              onPageChange={ChangePage}
              containerClassName={classes.paginationBttns}
              activeClassName={classes.paginationActive}
              marginPagesDisplayed={4}
              pageRangeDisplayed={4}
              onClick={scrollToTop}
              initialPage={currentPage - 1}
              previousClassName={classes.prev}
              nextClassName={classes.next}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
