import axios from "axios";

import axiosInstance from "../utils/request";

//UTILITY
export const getProgrammeApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetProgrammes");
  return data;
};

export const getCountriesApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetCountry");
  return data;
};

export const getPaymentOptionsApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetPaymentOoption");
  return data;
};

export const getFacultyApi = async (programmeId) => {
  const { data } = await axiosInstance.get(
    `api/v1/Util/GetFaculties?programmeId=${programmeId}`
  );
  return data;
};

export const getCourierApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetCuriar");
  return data;
};

export const getDegreeApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetDegree");
  return data;
};

export const getCampusApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetCampus");
  return data;
};

export const getVerificationBodyApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetVerificationBody");
  return data;
};

export const getDepartmentApi = async (facultyId) => {
  const { data } = await axiosInstance.get(
    `api/v1/Util/GetDepartment?facultyId=${facultyId}`
  );
  return data;
};

export const getStatesApi = async (countryId) => {
  const { data } = await axiosInstance.get(
    `api/v1/Util/GetStates?countryId=${countryId}`
  );
  return data;
};

export const getCityApi = async (stateId) => {
  const { data } = await axiosInstance.get(
    `api/v1/Util/GetCity?stateId=${stateId}`
  );
  return data;
};

export const getSessionApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetSession");
  return data;
};

export const getTitleApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetTitles");
  return data;
};
export const getGenderApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetGenders");
  return data;
};
export const getLevelApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetLevels");
  return data;
};
export const getCourseApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetCourses");
  return data;
};
export const getGradeSystemApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetGradeSystems");
  return data;
};
export const getSemestersApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetSemester");
  return data;
};
export const getGradesApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetGrades");
  return data;
};
export const getDegreeClassApi = async () => {
  const { data } = await axiosInstance.get("api/v1/Util/GetDegreeClass");
  return data;
};
export const getAllDepartmnetApi = async () => {
  const { data } = await axiosInstance.get("/api/v1/Util/GetAllDepartment");
  return data;
};

// ==========

//DIGITIZER
//Undigitized Requests
export const getRequireDigitizationTranscriptsApi = async () => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/GetRequireDigitizationTranscripts`
  );
  return data;
};
export const markTranscriptAsRequireReviewApi = async (orderNo) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/MarkTranscriptAsRequiredReview?RequestOrderNumber=${orderNo}`
  );
  return data;
};

//Flagged Requests
export const getFlaggedTranscriptsApi = async (isProficiency) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/GetFlagedTranscripts`,
    { params: { isProficiency: isProficiency || false } }
  );
  return data;
};

export const markTranscriptAsResolvedApi = async (orderNo, issuedId) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/MarkTranscriptIssuesAsResolved?RequestOrderNumber=${orderNo}&issueId=${issuedId}`
  );
  return data;
};

//Digitize
export const verifyStudentApi = async (matricNo) => {
  const { data } = await axiosInstance.get(
    `api/v1/Transcript/SearchStudent?matricNo=${matricNo}`
  );
  return data;
};
export const getExistingCoursesApi = async (userId) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/GetCourseRegisterations?userId=${userId}`
  );
  return data;
};
export const getGradeApi = async (gradesystemId) => {
  const { data } = await axiosInstance.get(
    `api/v1/Transcript/GetGrades?gradesystemId=${gradesystemId}`
  );
  return data;
};
export const getGradesByScoreApi = async (gradesystemId, score) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/GetGradeByScore?gradesystemId=${gradesystemId}&score=${score}`
  );
  return data;
};
export const calculateCGPApi = async (userId) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/calculateCGP?studentId=${userId}`
  );
  return data;
};
// ==========

//CREATE COURSE
export const removeCourseApi = async (courseId) => {
  const { data } = await axiosInstance.get(
    `api/v1/Admin/DeleteCourses?CourseId=${courseId}`
  );
  return data;
};
export const searchCourseApi = async (courseName) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Admin/SearchCourses?CourseName=${courseName}`
  );
  return data;
};

// ==========

//MAP COURSE
export const getMappedCoursesApi = async () => {
  const { data } = await axiosInstance.get(`/api/v1/Admin/CourseMappings`);
  return data;
};
// ==========

//PAYMENT
export const initializePaymentApi = async ({
  RequestOrderNumber,
  feeType,
  resultType,
  couriersType,
  isProficiency,
}) => {
  let url;
  if (resultType && couriersType) {
    url = `RequestOrderNumber=${RequestOrderNumber}&feeType=${feeType}&resultType=${resultType}&couriersType=${couriersType}&isProficiency=${isProficiency}`;
  } else {
    url = `RequestOrderNumber=${RequestOrderNumber}&feeType=${feeType}&resultType=${resultType}`;
  }
  const { data } = await axiosInstance.get(
    `/api/v1/Auth/InitialPayment/V2?${url}`
  );
  return data;
};
// ==========

//TRANSCRIPT MANAGEMENT
//Preview Result
export const previewResultApi = async (orderNo) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/PreviewResult?RequestOrderNumber=${orderNo}`
  );
  return data;
};

//Digitized Requests
export const getRequireReviewTranscriptsApi = async (isProficiency) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/GetRequiredReviewTranscripts`,
    { params: { isProficiency: isProficiency || false } }
  );
  return data;
};
export const markTranscriptAsRequireApprovalApi = async (orderNo) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/MarkTranscriptAsRequiredApproval?requestOrderNumber=${orderNo}`
  );
  return data;
};

//Awaiting Dispatch Requests
export const getRequireApprovalTranscriptsApi = async () => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/GetRequiredApprovalTranscripts`
  );
  return data;
};
export const markTranscriptAsReadyForDispatchApi = async (orderNo) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/MarkTranscriptAsReadyForDispatch?requestOrderNumber=${orderNo}`
  );
  return data;
};

//Dispatched Requests
export const getReadyForDispatchTranscriptsApi = async (isProficiency) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/GetReadyForDispatchTranscripts`,
    { params: { isProficiency: isProficiency || false } }
  );
  return data;
};

export const dispatchSoftCopyApi = async (orderNo, isProficiency) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/DispatchSoftCopyTranscript?requestOrderNumber=${orderNo}&isProficiency=${isProficiency}`
  );
  return data;
};

//Awaiting Signing
export const getRequireSigningTranscriptsApi = async () => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/GetAwaitingTranscripts`
  );
  return data;
};
export const markTranscriptAsRequireSigningApi = async (orderNo) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/MarkTranscriptAsAwaitingSigning?requestOrderNumber=${orderNo}`
  );
  return data;
};

//STUDENT DASHBOARD
export const getMyTranscriptsApi = async (id, isProficiency) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/GetMyTranscripts?studentId=${id}&isProficiency=${isProficiency}`
  );
  return data;
};

export const getMyProficiencyApi = async (id, isProficiency) => {
  const { data } = await axiosInstance.get(
    `/api/v1/Transcript/GetMyProficency?studentId=${id}&isProficiency=${isProficiency}`
  );
  return data;
};
