import { useContext, useEffect, useState } from "react";

import { CurrencyModal } from "../../../components";
import { TranscriptRequestContext } from "../../../contexts/transcriptRequest";
import { PaymentModal } from "./PaymentModal";
import { ShippingInfo } from "./ShippingInfo";

const ShippingOption = ({ handleBack }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { copyType } = useContext(TranscriptRequestContext);

  const content = {
    "Soft Copy": (
      <>
        {currentStep === 0 && (
          <CurrencyModal handleBack={handleBack} handleNext={setCurrentStep} />
        )}
        {currentStep === 1 && (
          <PaymentModal onClose={setCurrentStep} modal={false} />
        )}
      </>
    ),
    Wes: (
      <>
        {currentStep === 0 && (
          <CurrencyModal handleBack={handleBack} handleNext={setCurrentStep} />
        )}
        {currentStep === 1 && (
          <PaymentModal onClose={setCurrentStep} modal={false} />
        )}
      </>
    ),
    "Hard Copy": (
      <>
        {currentStep === 0 && (
          <CurrencyModal handleBack={handleBack} handleNext={setCurrentStep} />
        )}
        {currentStep === 1 && (
          <ShippingInfo onClick={setCurrentStep} handleBack={setCurrentStep} />
        )}
        {currentStep >= 2 && (
          <PaymentModal onClose={setCurrentStep} modal={true} />
        )}
      </>
    ),
  };

  const renderContent = content[copyType];

  return renderContent;
};

export default ShippingOption;
