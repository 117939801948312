import React from "react";
import { useReactToPrint } from "react-to-print";

const PaymentReportFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setRefetch,
  refetch,
  setPage,
}) => {
  return (
    <form className="flex flex-col gap-3 bg-white rounded-md p-4 w-full">
      <h2>Filter Transactions</h2>
      <div className="flex gap-2">
        <div className="flex flex-col gap-1">
          <label htmlFor="transacStart">Select date</label>
          <input
            className="p-2 rounded-sm border border-black"
            type="date"
            value={startDate}
            name="startDate"
            id="startDate"
            onChange={(e) => {
              setPage(1);
              setStartDate(e.target.value);
            }}
            required
          />
        </div>
        {/* <div className="flex flex-col gap-1">
          <label htmlFor="transacEnd">Select end date</label>
          <input
            className="p-2 rounded-sm border border-black"
            type="date"
          value={endDate}
            name="endDate"
            id="endDate"
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </div> */}
      </div>
      <div className="flex gap-3">
        <button
          className="border bg-blue-700 p-2 rounded-md text-white"
          type="button"
          onClick={() => {
            setRefetch(!refetch);
          }}
        >
          Filter
        </button>
        <button
          className="border border-blue-700 p-2 rounded-md"
          type="reset"
          onClick={() => {
            setEndDate();
            setStartDate(null);
            setPage(1);
          }}
        >
          Clear
        </button>
      </div>
    </form>
  );
};

export default PaymentReportFilter;
