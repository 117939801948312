import { HiArrowNarrowRight } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "../../UI";
import Logo from "../../asset/GoUni Logo.jpg";
import Image from "../../asset/LandingPageImage.webp";
import Diagonal from "../../asset/diagonal.jpg";
import Dots from "../../asset/polka dot.png";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <section className="h-screen flex flex-col relative">
      <div className="flex justify-between py-6 px-8 items-center fixed top-0 w-full">
        <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px]">
          <img
            className="max-w-full rounded-full"
            src={Logo}
            alt="gouni logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="flex gap-3 md:gap-6">
          <a
            href="https://docs.google.com/presentation/d/1Uj9WXPnoI5OBDD5lbmaDy_cDGiW9YoTJ8EH0McRD4Qg/edit#slide=id.g2916242a83d_0_165"
            target="blank"
            className="text-blue-700"
          >
            App Manual
          </a>
          {/* <Link to="/FAQ" className="text-blue-700">
            FAQ
          </Link> */}
          <Link to="/login" className="text-blue-700">
            Login
          </Link>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 px-10 flex-grow py-10 ">
        <div className="flex flex-col gap-4 basis-full md:basis-[40%] justify-center items-center flex-shrink-0">
          <div className="flex flex-col gap-2">
            <h1 className="text-[32px] font-semibold">
              Transcript Requests and Management System (TRAMS)
            </h1>
            <p className="">
              Our TRAMS Portal allows you to request and have your academic
              records delivered, seamlessly!
            </p>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 w-full flex-wrap">
            <Button
              onClick={() => navigate("/request")}
              className="flex gap-2 px-6 py-3"
            >
              Request for Transcript <HiArrowNarrowRight />
            </Button>
          </div>
        </div>

        <div className="basis-[60%] hidden sm:grid p-6 place-content-center">
          <div className="relative w-fit">
            <img
              src={Dots}
              alt="dot"
              className="absolute -top-5 -right-5 w-1/2 h-1/3 -z-10"
            />
            <img
              src={Diagonal}
              alt="diagonal"
              className="absolute -bottom-5 -left-5 w-2/3 h-2/3 -z-10"
            />
            <img
              src={Image}
              alt="student lounge"
              className="max-w-[550px] max-h-[350px]"
            />
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 text-center p-2 flex flex-col gap-2">
        <p className="text-sm">Powered by Meksoft</p>
        <p className="text-sm">
          Opening hours: 8:00am-4:00pm, Monday to Friday.
        </p>
        <p className="text-sm">Contact Phone Number: +234 913 415 2690</p>
      </div>
    </section>
  );
};

export default LandingPage;
