import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getAllDepartmnetApi,
  getCampusApi,
  getCityApi,
  getCountriesApi,
  getCourierApi,
  getCourseApi,
  getDegreeApi,
  getDegreeClassApi,
  getDepartmentApi,
  getFacultyApi,
  getGenderApi,
  getGradeSystemApi,
  getGradesApi,
  getLevelApi,
  getPaymentOptionsApi,
  getProgrammeApi,
  getSemestersApi,
  getSessionApi,
  getStatesApi,
  getTitleApi,
  initializePaymentApi,
} from "../api/Reads";
import {
  confirmPaymentApi,
  createProfileApi,
  forgotPasswordAPi,
  loginApi,
  searchStudentApi,
  uploadFileApi,
} from "../api/Writes";

//API'S

// ============

export const GeneralContext = createContext();

const GeneralProvider = ({ children }) => {
  //AUTH
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [regNo, setRegno] = useState("");
  const [modalId, setModalId] = useState("");
  // ==========

  //TRANSCRIPT REQUEST
  const [isReprinting, setIsReprinting] = useState();
  const [transcriptReceiptRequired, setTranscriptReceiptRequired] = useState();
  //==========

  //PAYMENT

  const [initializePaymentSchema, setInitializePaymentSchema] = useState({});
  //===========

  //UTILITIES
  const [allDepartments, setAllDepartments] = useState([]);
  const [grades, setGrades] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [programme, setProgramme] = useState();
  const [programmes, setProgrammes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [couriers, setCouriers] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [currency, setCurrency] = useState();
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [titles, setTitles] = useState([]);
  const [genders, setGenders] = useState([]);
  const [levels, setLevels] = useState([]);
  const [courses, setCourses] = useState([]);
  const [gradeSystems, setGradeSystems] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [degreeClasses, setDegreeClasses] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  // ==========

  //Deprecated
  const [sessionPulled, setSessionPulled] = useState(false);
  //===============

  const navigate = useNavigate();

  //  AUTHENTICATION
  //  search student
  const searchStudent = async (param) => {
    let response = await searchStudentApi(param);
    if (response.data.user !== null && response.success) {
      let user = response.data.user;
      setUser(response.data.user);
      let prog = programmes.filter((x) => x.key === response.data.programmeId);
      setProgramme(prog);
      setRegno(response.data.regNumber);
      localStorage.accessToken = JSON.stringify(response.data.jwt.accessToken);
      if (response.data.user.role === 1) {
        const role = ["Student"];
        localStorage.userRoles = JSON.stringify(role);
      }
      localStorage.regNumber = response.data.regNumber;
      localStorage.studentId = response.data.user.id;
      localStorage.credentials = JSON.stringify(response.data.jwt);
      localStorage.user = JSON.stringify(user);

      setIsLoggedIn(true);
    } else {
      setModalId(1);
      setIsLoggedIn(false);
    }
  };

  //  create profile
  const createProfile = async (param) => {
    try {
      let response = await createProfileApi(param);
      if (response.success) {
        toast.success("Profile created successfully");
        return true;
      } else {
        toast.error(response.message || "Oops, Something went wrong");
        return false;
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, Something went wrong");
    }
  };
  //  login
  const Login = async (param) => {
    try {
      let response = await loginApi(param);
      if (response.success) {
        let user = response.data.user;
        if (user.role === 1) {
          user.student = response.data.student;
          setIsReprinting(response.data.student.reprintData.isReprint);
          setTranscriptReceiptRequired(
            response.data.student.reprintData.isRecieptUploadRequired
          );
        }
        setUser(user);
        localStorage.accessToken = JSON.stringify(
          response.data.tokenPayload.accessToken
        );

        localStorage.user = JSON.stringify(user);
        localStorage.userRoles = JSON.stringify(response.data.role);
        if (response.data.student?.matricNumber) {
          localStorage.regNumber = response.data.student.matricNumber;
          localStorage.studentId = response.data.user.id;
        }
        setIsLoggedIn(true);
        toast.success("Login was successful");
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, Something went wrong");
    }
  };

  // reset password
  const ResetPassword = async (param) => {
    try {
      let response = await forgotPasswordAPi(param);
      console.log(response);
      if (response.success) {
        toast.success("Password reset successful");
      } else {
        toast.error(response.data || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const message = response?.data;
      toast.error(message || "Oops, Something went wrong");
    }
  };

  //  logout
  const logoutCustomer = () => {
    setIsLoggedIn(false);
    sessionStorage.clear();
    localStorage.clear();
    setUser({});
    navigate("/login");
  };
  //  modal
  const setModal = async (id) => {
    setModalId(id);
  };
  //=======================

  //PAYMENT
  const initializePayment = async (obj) => {
    console.log(obj);
    try {
      let response = await initializePaymentApi(obj);
      if (response.success) {
        return response.data;
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, Something went wrong");
    }
  };

  const confirmPayment = async (obj) => {
    try {
      let response = await confirmPaymentApi(obj);
    } catch ({ response }) {
      const { message } = response?.data;
    }
  };
  //=======================

  //UTILITIES
  //get grade v2
  const getGrades = async () => {
    let response = await getGradesApi();
    let items = response.data.map((item) => {
      item.key = item.id;
      item.value = item.grade;
      return item;
    });
    setGrades(items);
  };

  //get degreeClass
  const getDegreeClass = async () => {
    let response = await getDegreeClassApi();
    let items = response.data.map((item) => {
      item.key = item.id;
      item.value = item.name;
      return item;
    });
    setDegreeClasses(items);
  };
  //get semesters
  const getSemester = async () => {
    let response = await getSemestersApi();
    let items = response.data.map((item) => {
      item.key = item.id;
      item.value = item.name;
      return item;
    });
    setSemesters(items);
  };

  //get title
  const getTitle = async () => {
    let response = await getTitleApi();
    let items = response.data.map((item) => {
      item.key = item.id;
      item.value = item.name;
      return item;
    });
    setTitles(items);
  };

  //get gender
  const getGender = async () => {
    let response = await getGenderApi();
    let items = response.data.map((item) => {
      item.key = item.id;
      item.value = item.name;
      return item;
    });
    setGenders(items);
  };
  //get level
  const getLevel = async () => {
    let response = await getLevelApi();
    let items = response.data.map((item) => {
      item.key = item.id;
      item.value = item.name;
      return item;
    });
    items = [{ key: 0, value: "Select Level", disabled: true }, ...items];
    setLevels(items);
  };
  //get Course
  const getCourse = async () => {
    let response = await getCourseApi();
    let items = response.data.map((item) => {
      item.key = item.courseId;
      item.value = item.courseName;
      return item;
    });
    // items = [{ key: 0, value: "Select Course", disabled: true }, ...items];
    setCourses(items);
  };
  //get getGradeSystem
  const getGradeSystem = async () => {
    let response = await getGradeSystemApi();
    let items = response.data.map((item) => {
      item.key = item.id;
      item.value = item.name;
      return item;
    });
    setGradeSystems(items);
  };

  //  get countries
  const getCountries = async () => {
    let response = await getCountriesApi();
    let items = response.data.map((item) => {
      item.key = item.countryId;
      item.value = item.name;
      return item;
    });
    items = [{ key: 0, value: "Select Country", disabled: true }, ...items];
    setCountries(items);
  };

  // get states
  const getStates = async (countryId) => {
    let response = await getStatesApi(countryId);
    let items = response.data.map((item) => {
      item.key = item.stateId;
      item.value = item.name;
      return item;
    });
    items = [{ key: 0, value: "Select State", disabled: true }, ...items];
    setStates(items);
  };

  //  get cities
  const getCities = async (stateId) => {
    let response = await getCityApi(stateId);
    let items = response.data.map((item) => {
      item.key = item.cityId;
      item.value = item.name;
      return item;
    });
    items = [{ key: 0, value: "Select City", disabled: true }, ...items];
    setCities(items);
  };

  //  get programmes
  const getProgramme = async () => {
    let response = await getProgrammeApi();
    let items = [];
    items = response.data.map((item) => {
      item.key = item.programmeId;
      item.value = item.programme_Name;
      return item;
    });

    items = [{ key: 0, value: "Select Programme", disabled: true }, ...items];
    setProgrammes(items);
  };

  //  get degree
  const getDegree = async () => {
    let response = await getDegreeApi();

    let items = response.data.map((item) => {
      item.key = item.id;
      item.value = item.name;
      return item;
    });
    items = [{ key: 0, value: "Select Degree", disabled: true }, ...items];
    setDegrees(items);
  };

  //  get campuses
  const getCampuses = async () => {
    let response = await getCampusApi();

    let items = response.data.map((item) => {
      return { key: item.id, value: item.name };
    });
    items = [{ key: 0, value: "Select Campus", disabled: true }, ...items];
    setCampuses(items);
  };

  //  get session
  const getSession = async () => {
    let response = await getSessionApi();
    let items = response.data
      .map((item) => {
        return { key: item.id, value: item.name };
      })
      .reverse();
    items = [{ key: 0, value: "Select Session", disabled: true }, ...items];
    setSessions(items);
  };

  //  get faculty
  const getFaculty = async (programmeId) => {
    let response = await getFacultyApi(programmeId);
    let items = response.data.map((item) => {
      item.key = item.facultyId;
      item.value = item.facultyName;
      return item;
    });
    items = [{ key: 0, value: "Select Faculty", disabled: true }, ...items];
    // console.log(items);
    setFaculties(items);
  };

  //  get department
  const getDepartment = async (facultyId) => {
    let response = await getDepartmentApi(facultyId);
    let items = response.data.map((item) => {
      item.key = item.departmentId;
      item.value = item.departmentName;
      return item;
    });
    items = [{ key: 0, value: "Select Department", disabled: true }, ...items];
    setDepartments(items);
    return response.data[0].course_Duration;
  };

  const getAllDepartment = async () => {
    let response = await getAllDepartmnetApi();
    let items = response.data.map((item) => {
      item.key = item.departmentId;
      item.value = item.departmentName;
      return item;
    });
    items = [{ key: 0, value: "select department", disabled: true }, ...items];
    // console.log(items);
    setAllDepartments(items);
  };

  //  get courier
  const getCourier = async () => {
    let response = await getCourierApi();
    let items = response.data.map((item) => {
      item.key = item.id;
      item.value = item.name;
      return item;
    });
    // items = [{ key: 0, value: "select courier", disabled: true }, ...items];
    setCouriers(items);
  };

  //  get payment options
  const getPaymentOptions = async () => {
    let response = await getPaymentOptionsApi();
    // console.log(response.data[0]);
    let items = response.data.map((item) => {
      item.key = item.id;
      item.value = item.name;
      return item;
    });
    setPaymentOptions(items);
  };

  //upload image
  const uploadFile = async (param) => {
    try {
      const formData = new FormData();
      formData.append("file", param);
      let response = await uploadFileApi(formData);
      setSelectedFile(response);
      return response;
    } catch (error) {
      toast.error(error.message || "Oops something went wrong");
    }
  };

  return (
    <GeneralContext.Provider
      value={{
        allDepartments,
        getAllDepartment,
        degreeClasses,
        getDegreeClass,
        grades,
        getGrades,
        semesters,
        getSemester,
        courses,
        getCourse,
        gradeSystems,
        getGradeSystem,
        levels,
        getLevel,
        genders,
        getGender,
        titles,
        getTitle,
        uploadFile,
        getProgramme,
        programmes,
        programme,
        setProgramme,
        faculties,
        getFaculty,
        setFaculties,
        getDepartment,
        setDepartments,
        departments,
        getDegree,
        setDegrees,
        degrees,
        getCampuses,
        campuses,
        getSession,
        sessions,
        countries,
        getCountries,
        getStates,
        getCities,
        states,
        setStates,
        cities,
        setCities,
        selectedFile,
        setSelectedFile,
        couriers,
        getCourier,
        currency,
        setCurrency,
        getPaymentOptions,
        paymentOptions,
        setPaymentOptions,
        setSessions,
        sessionPulled,
        setSessionPulled,

        //AUTH
        userProfile,
        setUserProfile,
        isLoggedIn,
        setIsLoggedIn,
        Login,
        ResetPassword,
        searchStudent,
        createProfile,
        logoutCustomer,
        setModal,
        modalId,
        user,
        setUser,
        regNo,
        setRegno,
        //===========

        //TRANSCRIPT REQUEST
        isReprinting,
        transcriptReceiptRequired,
        //=========

        //PAYMENT
        initializePayment,

        initializePaymentSchema,
        setInitializePaymentSchema,
        confirmPayment,
        //==========
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralProvider;
