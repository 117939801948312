import { ImSpinner3 } from "react-icons/im";
import { useGetPaymentReportQuery } from "../../../Redux/services/endpoints/finance";
import { Table } from "../../../components";
import { useEffect } from "react";

const PaymentReportTable = ({ startDate, refresh, page, setPage }) => {
  const { data, isLoading, isError, refetch } = useGetPaymentReportQuery({
    pageNumber: page,
    pageSize: 30,
    startDate: startDate,
  });

  const columns = [
    { value: "Serial ID", label: "Serial ID", key: "Serial ID" },
    { value: "Order Number", label: "Order Number", key: "Order Number" },
    { value: "Full Name", label: "Full Name", key: "Full Name" },
    { value: "Email", label: "Email", key: "Email" },
    { value: "Request Type", label: "Request Type", key: "Request Type" },
    { value: "Request Date", label: "Request Date", key: "Request Date" },
    { value: "Matric No.", label: "Matric No", key: "Matric No" },
    {
      value: "Destination Email",
      label: "Destination Email",
      key: "Destination Email",
    },
    // { value: "Status" },
    { value: "Reference No", label: "Reference No", key: "Reference No" },
    { value: "Amount Paid", label: "Amount Paid", key: "Amount Paid" },
    { value: "Tramsfee", label: "Tramsfee", key: "Tramsfee" },
  ];

  useEffect(() => {
    refetch();
  }, [refresh, page]);

  const row = data?.data?.records?.map((item, index) => ({
    "Serial ID": index + 1,
    "Order Number": item.transcriptOrderNo,
    "Full Name": item.fullName,
    Email: item.email,
    "Request Type": item.resultRequestType_,
    "Request Date": item.createdAt.split("T")[0],
    "Matric No.": item.regNumber,
    "Destination Email": item.destinationEmail,
    "Reference No": item.referenceNo,
    Amount: item.ammoutPaid,
    // Status: item.status,
    Tramsfee: item.tramsfee,
  }));

  return (
    <div className="grid w-full h-full">
      {isLoading ? (
        <ImSpinner3 className="animate-spin place-self-center" size={32} />
      ) : (
        <Table
          columns={columns}
          data={row}
          id={"Payment Report"}
          pageCount={data?.data?.totalPages}
          itemCount={data?.data?.totalRecords}
          currentPage={data?.data?.currentPage}
          ChangePage={(value) => setPage(value?.selected)}
          downloadable={true}
        />
      )}
    </div>
  );
};
export default PaymentReportTable;
