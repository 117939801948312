import { useContext, useEffect, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import { useNavigate } from "react-router-dom";

import { Button, TextInput } from "../../UI";
import Image from "../../asset/SigninImage.webp";
import { GeneralContext } from "../../contexts/general.context";
//FIXME: why does tailwind style break when css import is removed?
import "../../index.css";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const { ResetPassword, isLoggedIn } = useContext(GeneralContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard/home");
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const handleResetPassword = async (e) => {
    setFormData((prev) => ({ ...prev, message: " " }));
    try {
      setIsPending(true);
      e.preventDefault();
      await ResetPassword(formData);
      setIsPending(false);
    } catch (err) {
      setIsPending(false);
    }
  };

  const handleTogglePassword = () => {
    setPasswordShown((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "password":
        setFormData((prev) => ({ ...prev, [name]: value.trim() }));
        break;
      case "email":
        setFormData((prev) => ({ ...prev, [name]: value }));
        break;
      default:
        return;
    }
  };
  return (
    <main className="grid grid-cols-1 md:grid-cols-2 h-screen">
      <div className="hidden md:block">
        <img src={Image} alt="campus" className="h-full object-cover" />
      </div>
      <div className="grid content-center p-8">
        <form
          className="max-w-screen-sm w-full sm:w-4/5 mx-auto grid gap-4"
          onSubmit={handleResetPassword}
        >
          <h1 className="font-semibold text-2xl">Reset Password</h1>
          <TextInput
            name="email"
            placeholder="Reg Number / User ID"
            onChange={handleInputChange}
            required
          />
          <TextInput
            name="password"
            placeholder="New Password"
            type={passwordShown ? "text" : "password"}
            onChange={handleInputChange}
            required
          />
          <div className="flex gap-2">
            <input
              type="checkbox"
              name="show-password"
              onChange={handleTogglePassword}
            />
            <p className="text-sm">Show Password</p>
          </div>

          <Button type="submit">
            {isPending ? (
              <ImSpinner3 className="animate-spin" size={22} />
            ) : (
              <span>Submit</span>
            )}
          </Button>
          <div className="grid gap-4">
            <p
              className="text-blue underline text-sm cursor-pointer"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </p>
          </div>
        </form>
      </div>
    </main>
  );
};
export default ForgotPassword;
